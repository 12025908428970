import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import { Provider } from "react-redux";

import store from "../redux/store.js";
import Page501 from "../container/pages/501/index.js";
import Dashboard from "../container/dashboard/index.tsx";

const Projects = lazy(() => import("../container/pages/Projects/index.js"));
const Page404 = lazy(() => import("../container/pages/404"));
const Client = lazy(() => import("../container/pages/Client"));
const Login = lazy(() => import("../container/pages/Login"));
const ClientDetails = lazy(() => import("../container/pages/ClientDetails"));
const IntegrationPage = lazy(
  () => import("../container/pages/IntegrationPage/index.jsx")
);
const ProjectEdit = lazy(
  () => import("../container/pages/ProjectEdit/index.js")
);

const App = lazy(() => import("../App.tsx"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: "/projects",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Projects />
          </Suspense>
        ),
      },
      {
        path: "/client",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Client />
          </Suspense>
        ),
      },
      {
        path: "/client/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ClientDetails />
          </Suspense>
        ),
      },
      {
        path: "/client/:id/integration",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <IntegrationPage />
          </Suspense>
        ),
      },
      {
        path: "/projects/new",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ProjectEdit />
          </Suspense>
        ),
      },
      {
        path: "/projects/edit/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ProjectEdit />
          </Suspense>
        ),
      },
      {
        path: "/501",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Page501 />
          </Suspense>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Dashboard />
          </Suspense>
        ),
      },
    ],
    errorElement: (
      <Suspense fallback={<div>Loading...</div>}>
        <Page404 />
      </Suspense>
    ),
  },
]);

export default router;
